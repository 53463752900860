<template>
    <user-dashboard-layout>
        <v-container fluid grid-list-md class="ma-0">
            <v-form>


                <v-layout row wrap>

                    <v-flex d-flex xs12 sm6 md6>
                        <v-layout row wrap>
                            <v-flex d-flex>
                                <v-card  >
                                    <v-card-text>
                                        If INTERIOR requested, make THREE ATTEMPTS at setting appointment
                                        and document calls/results at bottom of INTERIOR form.

                                    </v-card-text>
                                </v-card>
                            </v-flex>
                            <v-flex d-flex>
                                <v-layout row wrap>
                                    <v-flex

                                        d-flex
                                        xs12
                                        md6
                                    >
                                        <v-card


                                            min-width="285"
                                            max-width="285"

                                        >
                                            <v-card-text>

                                                <v-text-field
                                                    label="Inspector"
                                                    v-model="form.inspector"
                                                >

                                                </v-text-field>

                                                <v-text-field
                                                    label="Company"
                                                    v-model="form.company"
                                                >


                                                </v-text-field>

                                            </v-card-text>
                                        </v-card>
                                        <v-card
                                            min-width="285"
                                            max-width="285"


                                        >
                                            <v-card-text>
                                                <v-menu
                                                    ref="menu"
                                                    v-model="menu"
                                                    :close-on-content-click="false"
                                                    :return-value.sync="form.inspection_date"
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="auto"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field
                                                            v-model="form.inspection_date"
                                                            label="Picker in menu"
                                                            prepend-icon="mdi-calendar"
                                                            readonly
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        ></v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                        v-model="form.inspection_date"
                                                        no-title
                                                        scrollable
                                                    >
                                                        <v-spacer></v-spacer>
                                                        <v-btn
                                                            text
                                                            color="primary"
                                                            @click="menu = false"
                                                        >
                                                            Cancel
                                                        </v-btn>
                                                        <v-btn
                                                            text
                                                            color="primary"
                                                            @click="$refs.menu.save(form.inspection_date)"
                                                        >
                                                            OK
                                                        </v-btn>
                                                    </v-date-picker>
                                                </v-menu>
                                                <v-text-field
                                                    label="Underwriter"
                                                    v-model="form.underwriter">
                                                </v-text-field>


                                            </v-card-text>
                                        </v-card>
                                    </v-flex>

                                </v-layout>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                    <v-flex d-flex xs12 sm6 md6 child-flex>
                        <v-card >
                            <v-card-text>

                                <v-text-field
                                    label="Type of Policy"
                                    v-model="form.policy_type">

                                </v-text-field>

                                <v-text-field
                                    label="Policy number"
                                    v-model="form.policy_number"
                                >

                                </v-text-field>

                                <v-text-field
                                    label="Amount of Insurance"
                                    v-model="form.insurance_amount"
                                >

                                </v-text-field>


                                <v-menu
                                    ref="menu2"
                                    v-model="menu2"
                                    :close-on-content-click="false"
                                    :return-value.sync="form.date_ordered"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="form.date_ordered"
                                            label="Date Ordered"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="form.date_ordered"
                                        no-title
                                        scrollable
                                    >
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            text
                                            color="primary"
                                            @click="menu2 = false"
                                        >
                                            Cancel
                                        </v-btn>
                                        <v-btn
                                            text
                                            color="primary"
                                            @click="$refs.menu2.save(form.date_ordered)"
                                        >
                                            OK
                                        </v-btn>
                                    </v-date-picker>
                                </v-menu>

                                <v-menu
                                    ref="menu3"
                                    v-model="menu3"
                                    :close-on-content-click="false"
                                    :return-value.sync="form.date_returned"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="form.date_returned"
                                            label="Date returned"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="form.date_returned"
                                        no-title
                                        scrollable
                                    >
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            text
                                            color="primary"
                                            @click="menu3 = false"
                                        >
                                            Cancel
                                        </v-btn>
                                        <v-btn
                                            text
                                            color="primary"
                                            @click="$refs.menu3.save(form.date_returned)"
                                        >
                                            OK
                                        </v-btn>
                                    </v-date-picker>
                                </v-menu>


                                <v-text-field
                                    label="Underwriter Review "
                                    v-model="form.underwriter_review"
                                >

                                </v-text-field>

                            </v-card-text>
                        </v-card>
                    </v-flex>

                </v-layout>


                <v-row>
                    <v-col cols="12" style="border:2px solid black">
                        <v-text-field
                            v-model="form.applicant_name"
                            label="Name of applicant">

                        </v-text-field>
                        <v-text-field
                            v-model="form.property_location"

                            label="Location of property">

                        </v-text-field>
                        <v-text-field
                            v-model="form.person_to_contact"

                            label="Person to contact">

                        </v-text-field>
                        <v-text-field
                            v-model="form.agent_name"

                            label="Name of agent">

                        </v-text-field>
                        <v-text-field
                            v-model="form.phone"

                            label="Phone">

                        </v-text-field>
                        <v-text-field
                            v-model="form.phone2"

                            label="Phone">

                        </v-text-field>


                    </v-col>


                </v-row>

                <p>INTERVIEW and OCCUPANCY</p>
                <v-row>
                    <v-col cols="12" style="border:2px solid black">
                        <v-radio-group
                            v-model="form.interview"
                            row
                            label="Interview"
                        >
                            <v-radio
                                label="Yes– Mr."
                                value="Yes– Mr."
                            ></v-radio>
                            <v-radio
                                label="Yes –Mrs."
                                value="Yes –Mrs."
                            ></v-radio>
                            <v-radio
                                label="Yes - Tenant"
                                value="Yes - Tenant"
                            ></v-radio>
                            <v-radio
                                label="Yes- Other"
                                value="Yes- Other"
                            ></v-radio>
                            <v-radio
                                label="No-Refused"
                                value="No-Refused"
                            ></v-radio>
                            <v-radio
                                label="No –Not home"
                                value="No –Not home"
                            ></v-radio>
                        </v-radio-group>

                        <v-radio-group
                            v-model="form.families_apartments"
                            row
                            label="No. of Families / Apartments"
                        >
                            <v-radio
                                label="1"
                                value="1"
                            ></v-radio>
                            <v-radio
                                label="2"
                                value="2"
                            ></v-radio>
                            <v-radio
                                label="3"
                                value="3"
                            ></v-radio>
                            <v-radio
                                label="4"
                                value="4"
                            ></v-radio>
                        </v-radio-group>
                        <v-radio-group
                            v-model="form.construction"
                            row
                            label="Construction"
                        >
                            <v-radio
                                label="Frame"
                                value="Frame"
                            ></v-radio>
                            <v-radio
                                label="Brick"
                                value="Brick"
                            ></v-radio>
                            <v-radio
                                label="Brick Veneer"
                                value="Brick Veneer"
                            ></v-radio>
                            <v-radio
                                label="Fire resistive"
                                value="Fire resistive"
                            ></v-radio>
                        </v-radio-group>
                        <v-radio-group
                            v-model="form.occupancy"
                            row
                            label="Occupancy"
                        >
                            <v-radio
                                label="Occupied"
                                value="Occupied"
                            ></v-radio>
                            <v-radio
                                label="Owner"
                                value="Owner"
                            ></v-radio>
                            <v-radio
                                label="Tenant"
                                value="Tenant"
                            ></v-radio>
                            <v-radio
                                label="Unknown"
                                value="Unknown"
                            ></v-radio>
                            <v-radio
                                label="Vacant"
                                value="Vacant"
                            ></v-radio>
                            <v-radio
                                label="Seasonal"
                                value="Seasonal"
                            ></v-radio>
                        </v-radio-group>
                        <v-radio-group
                            v-model="form.vacancy_determined"
                            row
                            label="Vacancy determined by"
                        >
                            <v-radio
                                label="Empty / No furniture"
                                value="Empty / No furniture"
                            ></v-radio>
                            <v-radio
                                label="Meters off"
                                value="Meters off"
                            ></v-radio>
                            <v-radio
                                label="Realtor lock box"
                                value="Realtor lock box"
                            ></v-radio>
                            <v-radio
                                label="Neighbor notified"
                                value="Neighbor notified"
                            ></v-radio>   <!--DOBAVIT POLE -->
                        </v-radio-group>
                        <v-radio-group
                            v-model="form.if_vacant"
                            row
                            label="If Vacant"
                        >
                            <v-radio
                                label="Secured"
                                value="Secured"
                            ></v-radio>
                            <v-radio
                                label="Under rehabilitation (Comment/Photo of rehab areas)"
                                value="Under rehabilitation (Comment/Photo of rehab areas)"
                            ></v-radio>
                            <v-radio
                                label="Open to Trespass"
                                value="Open to Trespass"
                            ></v-radio>
                            <v-radio
                                label="Evidence of vandalism"
                                value="Evidence of vandalism"
                            ></v-radio>
                        </v-radio-group>
                        <v-radio-group
                            v-model="form.fire_dept"
                            row
                            label="Fire Dept"
                        >
                            <v-radio
                                label="Paid"
                                value="Paid"
                            ></v-radio>
                            <v-radio
                                label="Volunteer"
                                value="Volunteer"
                            ></v-radio>
                            <v-radio
                                label="Combination"
                                value="Combination"
                            ></v-radio>

                        </v-radio-group>
                        <v-radio-group
                            v-model="form.fire_evidence"
                            row
                            label="Evidence of previous fire damage"
                        >
                            <v-radio
                                label="Yes"
                                value="Yes"
                            ></v-radio>
                            <v-radio
                                label="No"
                                value="No"
                            ></v-radio>
                            <v-radio>  <!--TEkstovoe pole  -->
                                label="3"
                                value="3"
                            </v-radio>

                        </v-radio-group>
                        <v-radio-group
                            v-model="form.firewalls_between"
                            row
                            label="Firewalls between units"
                        >
                            <v-radio
                                label="Yes"
                                value="Yes"
                            ></v-radio>
                            <v-radio
                                label="No"
                                value="No"
                            ></v-radio>

                        </v-radio-group>
                        <v-radio-group
                            v-model="row"
                            row
                            label="No. of Units within firewall:"
                        >
                            <v-radio
                                label="Trailer/ Mobile home"
                                value="Trailer/ Mobile home"
                            ></v-radio>
                            <v-radio
                                label="Modular home"
                                value="Modular home"
                            ></v-radio>
                            <v-radio
                                label="Property is situated on a farm."
                                value="Property is situated on a farm."
                            ></v-radio>

                        </v-radio-group>


                    </v-col>


                </v-row>
                SECTION I HOUSE EXTERIOR CONDITIONS

                <v-row>
                    <v-col cols="2" style="border:2px solid black">
                        Item


                    </v-col>
                    <v-col cols="2">
                        <v-row>
                            <v-col cols="12" style="border:2px solid black">
                                Degree


                            </v-col>
                            <v-col cols="6" style="border:2px solid black">
                                Major


                            </v-col>
                            <v-col cols="6" style="border:2px solid black">
                                Minor
                            </v-col>

                        </v-row>
                    </v-col>
                    <v-col cols="7" style="border:2px solid black">
                        Specific problem


                    </v-col>
                    <v-col cols="1" style="border:2px solid black">
                        Other


                    </v-col>

                    <v-col cols="2" style="border:2px solid black">
                        Foundation


                    </v-col>

                    <v-col cols="2" style="border:2px solid black">
                        <v-radio-group
                            v-model="form.foundation_degree"
                            row

                        >
                            <v-radio

                                value="Major"

                            ></v-radio>
                            <v-radio

                                value="Minor"

                            ></v-radio>

                        </v-radio-group>


                    </v-col>
                    <v-col cols="7" style="border:2px solid black">
                        <v-row>
                            <v-col>

                                <v-checkbox label="Cracking"
                                            v-model="form.foundation_cracking"
                                            value="Cracking">

                                </v-checkbox>
                            </v-col>
                            <v-col>

                                <v-checkbox label="Crumbling"
                                            v-model="form.foundation_crumbling"
                                            value="Crumbling">

                                </v-checkbox>
                            </v-col>
                            <v-col>

                                <v-checkbox label="Uneven"
                                            v-model="form.foundation_uneven"

                                            value="Uneven">

                                </v-checkbox>
                            </v-col>
                            <v-col>

                                <v-checkbox label="Open"
                                            v-model="form.foundation_open"

                                            value="Open">

                                </v-checkbox>
                            </v-col>
                            <v-col>

                                <v-checkbox label="Non-continuous"
                                            v-model="form.foundation_non_continuous"

                                            value="Non-continuous">

                                </v-checkbox>
                            </v-col>


                        </v-row>


                    </v-col>
                    <v-col cols="1" style="border:2px solid black">

                        <v-checkbox label=" "
                                    v-model="form.foundation_other"

                                    value="Other">

                        </v-checkbox>

                    </v-col>

                    <v-col cols="2" style="border:2px solid black">
                        2)Steps


                    </v-col>

                    <v-col cols="2" style="border:2px solid black">
                        <v-radio-group
                            v-model="form.steps_degree"
                            row

                        >
                            <v-radio


                                value="Major"
                            ></v-radio>
                            <v-radio


                                value="Minor"
                            ></v-radio>

                        </v-radio-group>


                    </v-col>
                    <v-col cols="7" style="border:2px solid black">
                        <v-row>
                            <v-col>

                                <v-checkbox label="No railing (over 36” )"
                                            v-model="form.steps_no_railing"

                                            value="No railing (over 36” )">

                                </v-checkbox>
                            </v-col>
                            <v-col>

                                <v-checkbox label="Cracking"
                                            v-model="form.steps_cracking"

                                            value="Cracking">

                                </v-checkbox>
                            </v-col>
                            <v-col>

                                <v-checkbox label="Crumbling"
                                            v-model="form.steps_crumbling"

                                            value="Crumbling">

                                </v-checkbox>
                            </v-col>
                            <v-col>

                                <v-checkbox label="Rotting"
                                            v-model="form.steps_rotting"

                                            value="Rotting">

                                </v-checkbox>
                            </v-col>
                            <v-col>

                                <v-checkbox label="Leaning"
                                            v-model="form.steps_leaning"

                                            value="Leaning">

                                </v-checkbox>
                            </v-col>


                        </v-row>


                    </v-col>
                    <v-col cols="1" style="border:2px solid black">

                        <v-checkbox label=" "
                                    v-model="form.steps_other"
                                    value="Other">

                        </v-checkbox>

                    </v-col>

                    <v-col cols="2" style="border:2px solid black">
                        3) Porch


                    </v-col>

                    <v-col cols="2" style="border:2px solid black">
                        <v-radio-group
                            v-model="form.porch_degree"
                            row

                        >
                            <v-radio


                                value="Major"
                            ></v-radio>
                            <v-radio


                                value="Minor"
                            ></v-radio>

                        </v-radio-group>


                    </v-col>
                    <v-col cols="7" style="border:2px solid black">
                        <v-row>
                            <v-col>

                                <v-checkbox label="No railing (over 36” )"
                                            v-model="form.porch_no_railing"

                                            value="No railing (over 36” )">

                                </v-checkbox>
                            </v-col>
                            <v-col>

                                <v-checkbox label="Cracking"
                                            v-model="form.porch_cracking"

                                            value="Cracking">

                                </v-checkbox>
                            </v-col>
                            <v-col>

                                <v-checkbox label="Crumbling"
                                            v-model="form.porch_crumbling"

                                            value="Crumbling">

                                </v-checkbox>
                            </v-col>
                            <v-col>

                                <v-checkbox label="Rotting"
                                            v-model="form.porch_rotting"

                                            value="Rotting">

                                </v-checkbox>
                            </v-col>
                            <v-col>

                                <v-checkbox label="Leaning"
                                            v-model="form.porch_leaning"

                                            value="Leaning">

                                </v-checkbox>
                            </v-col>

                        </v-row>


                    </v-col>
                    <v-col cols="1" style="border:2px solid black">

                        <v-checkbox label=" "
                                    v-model="form.porch_other"

                                    value="Other">

                        </v-checkbox>

                    </v-col>

                    <v-col cols="2" style="border:2px solid black">
                        4)Roof
                    </v-col>

                    <v-col cols="2" style="border:2px solid black">
                        <v-radio-group
                            v-model="form.roof_degree"
                            row

                        >
                            <v-radio


                                value="Major"
                            ></v-radio>
                            <v-radio


                                value="Minor"
                            ></v-radio>

                        </v-radio-group>


                    </v-col>
                    <v-col cols="7" style="border:2px solid black">
                        <v-row>
                            <v-col>

                                <v-checkbox label="Curled / Lifting"
                                            v-model="form.roof_curled"

                                            value="Curled / Lifting">

                                </v-checkbox>
                            </v-col>
                            <v-col>

                                <v-checkbox label="Crumbling"
                                            v-model="form.roof_crumbling"

                                            value="Crumbling">

                                </v-checkbox>
                            </v-col>
                            <v-col>

                                <v-checkbox label="Rotting"
                                            v-model="form.roof_rotting"

                                            value="Rotting">

                                </v-checkbox>
                            </v-col>
                            <v-col>

                                <v-checkbox label="Uneven"
                                            v-model="form.roof_uneven"

                                            value="Uneven">

                                </v-checkbox>
                            </v-col>
                            <v-col>

                                <v-checkbox label="Patched"
                                            v-model="form.roof_patched"

                                            value="Patched">

                                </v-checkbox>
                            </v-col>
                            <v-col>

                                <v-checkbox label="Moss"
                                            v-model="form.roof_moss"

                                            value="Moss">

                                </v-checkbox>
                            </v-col>

                        </v-row>


                    </v-col>
                    <v-col cols="1" style="border:2px solid black">

                        <v-checkbox label="Other"
                                    v-model="form.roof_other"

                                    value="Other">

                        </v-checkbox>

                    </v-col>

                    <v-col cols="2" style="border:2px solid black">
                        5) Siding


                    </v-col>

                    <v-col cols="2" style="border:2px solid black">
                        <v-radio-group
                            v-model="form.siding_degree"
                            row

                        >
                            <v-radio


                                value="Major"
                            ></v-radio>
                            <v-radio

                                value="Minor"
                            ></v-radio>

                        </v-radio-group>


                    </v-col>
                    <v-col cols="7" style="border:2px solid black">
                        <v-row>
                            <v-col>

                                <v-checkbox label="Crumbling"
                                            v-model="form.siding_crumbling"

                                            value="Crumbling">

                                </v-checkbox>
                            </v-col>
                            <v-col>

                                <v-checkbox label="Rotting"
                                            v-model="form.siding_rotting"

                                            value="Rotting">

                                </v-checkbox>
                            </v-col>
                            <v-col>

                                <v-checkbox label="Paint peeling"
                                            v-model="form.siding_peeling"

                                            value="Paint peeling">

                                </v-checkbox>
                            </v-col>
                            <v-col>

                                <v-checkbox label="Damaged"
                                            v-model="form.siding_damaged"

                                            value="Damaged">

                                </v-checkbox>
                            </v-col>
                            <v-col>

                                <v-checkbox label="Missing"
                                            v-model="form.siding_missing"

                                            value="Missing">

                                </v-checkbox>
                            </v-col>


                        </v-row>


                    </v-col>
                    <v-col cols="1" style="border:2px solid black">

                        <v-checkbox label=""
                                    v-model="form.siding_other"

                                    value="Other">

                        </v-checkbox>

                    </v-col>

                    <v-col cols="2" style="border:2px solid black">
                        6) Brick


                    </v-col>

                    <v-col cols="2" style="border:2px solid black">
                        <v-radio-group
                            v-model="form.brick_degree"
                            row

                        >
                            <v-radio


                                value="Major"
                            ></v-radio>
                            <v-radio


                                value="Minor"
                            ></v-radio>

                        </v-radio-group>


                    </v-col>
                    <v-col cols="7" style="border:2px solid black">
                        <v-row>
                            <v-col>

                                <v-checkbox label="Cracking"
                                            v-model="form.brick_cracking"

                                            value="Cracking">

                                </v-checkbox>
                            </v-col>
                            <v-col>

                                <v-checkbox label="Crumbling"
                                            v-model="form.brick_crumbling"

                                            value="Crumbling">

                                </v-checkbox>
                            </v-col>
                            <v-col>

                                <v-checkbox label="Tuck-pointing"
                                            v-model="form.brick_tuck"

                                            value="Tuck-pointing">

                                </v-checkbox>
                            </v-col>


                        </v-row>


                    </v-col>
                    <v-col cols="1" style="border:2px solid black">

                        <v-checkbox label=" "
                                    v-model="form.brick_other"

                                    value="Other">

                        </v-checkbox>

                    </v-col>

                    <v-col cols="2" style="border:2px solid black">
                        7) Soffits/ Eaves


                    </v-col>

                    <v-col cols="2" style="border:2px solid black">
                        <v-radio-group
                            v-model="form.soffits_degree"
                            row

                        >
                            <v-radio

                                value="Major"
                            ></v-radio>
                            <v-radio

                                value="Minor"
                            ></v-radio>

                        </v-radio-group>


                    </v-col>
                    <v-col cols="7" style="border:2px solid black">
                        <v-row>
                            <v-col>

                                <v-checkbox label="Rotting"
                                            v-model="form.soffits_rotting"

                                            value="Rotting">

                                </v-checkbox>
                            </v-col>
                            <v-col>

                                <v-checkbox label="Paint peeling"
                                            v-model="form.soffits_peeling"

                                            value="Paint peeling">

                                </v-checkbox>
                            </v-col>
                            <v-col>

                                <v-checkbox label="Damaged"
                                            v-model="form.soffits_damaged"

                                            value="Damaged">

                                </v-checkbox>
                            </v-col>

                        </v-row>


                    </v-col>
                    <v-col cols="1" style="border:2px solid black">

                        <v-checkbox label=" "
                                    v-model="form.soffits_other"

                                    value="Others">

                        </v-checkbox>

                    </v-col>

                    <v-col cols="2" style="border:2px solid black">
                        Gutters


                    </v-col>

                    <v-col cols="2" style="border:2px solid black">
                        <v-radio-group
                            v-model="form.gutters_degree"
                            row

                        >
                            <v-radio
                                value="Major"
                            ></v-radio>
                            <v-radio

                                value="Minor"
                            ></v-radio>

                        </v-radio-group>


                    </v-col>
                    <v-col cols="7" style="border:2px solid black">
                        <v-row>
                            <v-col>

                                <v-checkbox label="Rotting"
                                            v-model="form.gutters_rotting"

                                            value="Rotting">

                                </v-checkbox>
                            </v-col>
                            <v-col>

                                <v-checkbox label="Missing"
                                            v-model="form.gutters_missing"

                                            value="Missing">

                                </v-checkbox>
                            </v-col>
                            <v-col>

                                <v-checkbox label="Damaged"
                                            v-model="form.gutters_damaged"

                                            value="Damaged">

                                </v-checkbox>
                            </v-col>


                        </v-row>


                    </v-col>
                    <v-col cols="1" style="border:2px solid black">

                        <v-checkbox label=" "
                                    v-model="form.gutters_other"

                                    value="Others">

                        </v-checkbox>

                    </v-col>

                    <v-col cols="2" style="border:2px solid black">
                        9) Chimney


                    </v-col>

                    <v-col cols="2" style="border:2px solid black">
                        <v-radio-group
                            v-model="form.chimney_degree"
                            row

                        >
                            <v-radio


                                value="Major"
                            ></v-radio>
                            <v-radio
                                value="Minor"
                            ></v-radio>

                        </v-radio-group>


                    </v-col>
                    <v-col cols="7" style="border:2px solid black">
                        <v-row>
                            <v-col>

                                <v-checkbox label="Cracking"
                                            v-model="form.chimney_cracking"

                                            value="Cracking">

                                </v-checkbox>
                            </v-col>
                            <v-col>

                                <v-checkbox label="Crumbling"
                                            v-model="form.chimney_crumbling"

                                            value="Crumbling">

                                </v-checkbox>
                            </v-col>
                            <v-col>

                                <v-checkbox label="Tuck-pointing"
                                            v-model="form.chimney_tuck"

                                            value="Tuck-pointing">

                                </v-checkbox>
                            </v-col>
                            <v-col>

                                <v-checkbox label="Leaning"
                                            v-model="form.chimney_leaninng"

                                            value="Leaning">

                                </v-checkbox>
                            </v-col>

                        </v-row>


                    </v-col>
                    <v-col cols="1" style="border:2px solid black">

                        <v-checkbox label=" "
                                    v-model="form.chimney_other"

                                    value="Others">

                        </v-checkbox>

                    </v-col>

                    <v-col cols="2" style="border:2px solid black">
                        10) Window


                    </v-col>

                    <v-col cols="2" style="border:2px solid black">
                        <v-radio-group
                            v-model="form.window_degree"
                            row

                        >
                            <v-radio


                                value="Major"
                            ></v-radio>
                            <v-radio
                                value="Minor"
                            ></v-radio>

                        </v-radio-group>


                    </v-col>
                    <v-col cols="7" style="border:2px solid black">
                        <v-row>
                            <v-col>

                                <v-checkbox label="Rotting"
                                            v-model="form.window_rotting"
                                            value="Rotting">

                                </v-checkbox>
                            </v-col>
                            <v-col>

                                <v-checkbox label="Paint peeling"
                                            v-model="form.window_peeling"
                                            value="Paint peeling">

                                </v-checkbox>
                            </v-col>
                            <v-col>

                                <v-checkbox label="Damaged"
                                            v-model="form.window_damaged"
                                            value="Damaged">

                                </v-checkbox>
                            </v-col>


                        </v-row>


                    </v-col>
                    <v-col cols="1" style="border:2px solid black">

                        <v-checkbox label=" "
                                    v-model="form.window_other"
                                    value="Other">

                        </v-checkbox>

                    </v-col>
                    <v-col cols="12" style="border:2px solid black">

                        11) Upper level door has no deck, steps or railing
                        <v-col>

                            <v-radio-group
                                v-model="form.upper_door"
                                row

                            >
                                <v-radio

                                    value="Yes"
                                ></v-radio>
                                <v-radio

                                    value="No"
                                ></v-radio>

                            </v-radio-group>
            <v-row>

                <v-col>
                    If yes, missing items(s):
                    <v-checkbox label="Deck"
                                v-model="form.upper_door_deck"
                                value="Deck">

                    </v-checkbox>
                </v-col>
                <v-col>

                    <v-checkbox label="Steps"
                                v-model="form.upper_door_steps"
                                value="Steps">

                    </v-checkbox>
                </v-col>
                <v-col>

                    <v-checkbox label="Railing"
                                v-model="form.upper_door_railing"
                                value="Railing">

                    </v-checkbox>
                </v-col>
            </v-row>

                        </v-col>


                    </v-col>

                </v-row>

                OTHER STRUCTURES
                <v-row>
                    <v-col cols="2" style="border:2px solid black">
                        Item


                    </v-col>
                    <v-col cols="2">
                        <v-row>
                            <v-col cols="12" style="border:2px solid black">
                                Degree


                            </v-col>
                            <v-col cols="6" style="border:2px solid black">
                                Major


                            </v-col>
                            <v-col cols="6" style="border:2px solid black">
                                Minor
                            </v-col>

                        </v-row>
                    </v-col>
                    <v-col cols="7" style="border:2px solid black">
                        Specific problem


                    </v-col>
                    <v-col cols="1" style="border:2px solid black">
                        Other


                    </v-col>

                    <v-col cols="2" style="border:2px solid black">
                        1) Detached Garage


                    </v-col>

                    <v-col cols="2" style="border:2px solid black">
                        <v-radio-group
                            v-model="form.garage_degree"
                            row

                        >
                            <v-radio

                                value="Major"
                            ></v-radio>
                            <v-radio
                                value="Minor"
                            ></v-radio>

                        </v-radio-group>


                    </v-col>
                    <v-col cols="7" style="border:2px solid black">
                        <v-row>
                            <v-col>

                                <v-checkbox label="Siding"
                                            v-model="form.garage_siding"
                                            value="Siding">

                                </v-checkbox>
                            </v-col>
                            <v-col>

                                <v-checkbox label="Foundation"
                                            v-model="form.garage_foundation"
                                            value="Foundation">

                                </v-checkbox>
                            </v-col>
                            <v-col>

                                <v-checkbox label="Roof"
                                            v-model="form.garage_roof"
                                            value="Roof">

                                </v-checkbox>
                            </v-col>
                            <v-col>

                                <v-checkbox label="Paint peeling"
                                            v-model="form.garage_paint"
                                            value="Paint peeling">

                                </v-checkbox>
                            </v-col>
                            <v-col>

                                <v-checkbox label="Windows"
                                            v-model="form.garage_windows"
                                            value="Windows">

                                </v-checkbox>
                            </v-col>
                            <v-col>

                                <v-checkbox label="Doors"
                                            v-model="form.garage_doors"
                                            value="Doors">

                                </v-checkbox>
                            </v-col>


                        </v-row>


                    </v-col>
                    <v-col cols="1" style="border:2px solid black">

                        <v-checkbox label=" "
                                    v-model="form.garage_other"
                                    value="Other">

                        </v-checkbox>

                    </v-col>

                    <v-col cols="2" style="border:2px solid black">
                        2) Shed


                    </v-col>

                    <v-col cols="2" style="border:2px solid black">
                        <v-radio-group
                            v-model="form.shed_degree"
                            row

                        >
                            <v-radio

                                value="Major"
                            ></v-radio>
                            <v-radio

                                value="Minor"
                            ></v-radio>

                        </v-radio-group>


                    </v-col>
                    <v-col cols="7" style="border:2px solid black">
                        <v-row>
                            <v-col>

                                <v-checkbox label="Siding"
                                            v-model="form.shed_siding"

                                            value="Siding">

                                </v-checkbox>
                            </v-col>
                            <v-col>

                                <v-checkbox label="Foundation"
                                            v-model="form.shed_foundation"

                                            value="Foundation">

                                </v-checkbox>
                            </v-col>
                            <v-col>

                                <v-checkbox label="Roof"
                                            v-model="form.shed_roof"

                                            value="Roof">

                                </v-checkbox>
                            </v-col>
                            <v-col>

                                <v-checkbox label="Paint peeling"
                                            v-model="form.shed_paint"

                                            value="Paint peeling">

                                </v-checkbox>
                            </v-col>
                            <v-col>

                                <v-checkbox label="Windows"
                                            v-model="form.shed_windows"

                                            value="Windows">

                                </v-checkbox>
                            </v-col>
                            <v-col>

                                <v-checkbox label="Doors"
                                            v-model="form.shed_doors"

                                            value="Doors">

                                </v-checkbox>
                            </v-col>


                        </v-row>


                    </v-col>
                    <v-col cols="1" style="border:2px solid black">

                        <v-checkbox label=" "
                                    v-model="form.shed_other"

                                    value="Other">

                        </v-checkbox>

                    </v-col>

                    <v-col cols="2" style="border:2px solid black">
                        3) Out building

                    </v-col>

                    <v-col cols="2" style="border:2px solid black">
                        <v-radio-group
                            v-model="form.out_building_degree"
                            row

                        >
                            <v-radio


                                value="Major"
                            ></v-radio>
                            <v-radio


                                value="Minor"
                            ></v-radio>

                        </v-radio-group>


                    </v-col>
                    <v-col cols="7" style="border:2px solid black">
                        <v-row>
                            <v-col>

                                <v-checkbox label="Siding"
                                            v-model="form.out_building_siding"
                                            value="Siding">

                                </v-checkbox>
                            </v-col>
                            <v-col>

                                <v-checkbox label="Foundation"
                                            v-model="form.out_building_foundation"
                                            value="Foundation">

                                </v-checkbox>
                            </v-col>
                            <v-col>

                                <v-checkbox label="Roof"
                                            v-model="form.out_building_roof"
                                            value="Roof">

                                </v-checkbox>
                            </v-col>
                            <v-col>

                                <v-checkbox label="Paint peeling"
                                            v-model="form.out_building_paint"
                                            value="Paint peeling">

                                </v-checkbox>
                            </v-col>
                            <v-col>

                                <v-checkbox label="Windows"
                                            v-model="form.out_building_windows"
                                            value="Windows">

                                </v-checkbox>
                            </v-col>
                            <v-col>

                                <v-checkbox label="Doors"
                                            v-model="form.out_building_doors"
                                            value="Doors">

                                </v-checkbox>
                            </v-col>


                        </v-row>


                    </v-col>
                    <v-col cols="1" style="border:2px solid black">

                        <v-checkbox label=" "
                                    v-model="form.out_building_other"
                                    value="Other">

                        </v-checkbox>

                    </v-col>

                    <v-col cols="2" style="border:2px solid black">
                        4) Pool (Photo)


                    </v-col>

                    <v-col cols="2" style="border:2px solid black">
                        <v-radio-group
                            v-model="form.pool_degree"
                            row

                        >
                            <v-radio
                                value="Major"
                            ></v-radio>
                            <v-radio
                                value="Minor"
                            ></v-radio>

                        </v-radio-group>


                    </v-col>
                    <v-col cols="7" style="border:2px solid black">
                        <v-row>
                            <v-col>

                                <v-checkbox label="In-ground"
                                            v-model="form.pool_inground"
                                            value="In-ground">

                                </v-checkbox>
                            </v-col>
                            <v-col>

                                <v-checkbox label="Above ground"
                                            v-model="form.pool_above"
                                            value="Above ground">

                                </v-checkbox>
                            </v-col>
                            <v-col>

                                <v-checkbox label="Unfenced"
                                            v-model="form.pool_unfenced"
                                            value="Unfenced">

                                </v-checkbox>
                            </v-col>
                            <v-col>

                                <v-checkbox label="Unlocked gate"
                                            v-model="form.pool_unlocked"
                                            value="Unlocked gate">

                                </v-checkbox>
                            </v-col>
                            <v-col>

                                <v-checkbox label="Windows"
                                            v-model="form.pool_windows"
                                            value="Windows">

                                </v-checkbox>
                            </v-col>
                            <v-col>

                                <v-checkbox label="Poor condition"
                                            v-model="form.pool_poor"
                                            value="Poor condition">

                                </v-checkbox>
                            </v-col>


                        </v-row>


                    </v-col>
                    <v-col cols="1" style="border:2px solid black">

                        <v-checkbox label=" "
                                    v-model="form.pool_other"
                                    value="Other">

                        </v-checkbox>

                    </v-col>

                    <v-col cols="2" style="border:2px solid black">
                        5) Trampoline


                    </v-col>

                    <v-col cols="2" style="border:2px solid black">
                        <v-radio-group
                            v-model="form.trampoline_degree"
                            row

                        >
                            <v-radio

                                value="Major"
                            ></v-radio>
                            <v-radio
                                value="Minor"
                            ></v-radio>

                        </v-radio-group>


                    </v-col>
                    <v-col cols="7" style="border:2px solid black">
                        <v-row>

                            <v-col>

                                <v-checkbox label="Unfenced"
                                            v-model="form.trampoline_unfenced"
                                            value="Unfenced">

                                </v-checkbox>
                            </v-col>
                            <v-col>

                                <v-checkbox label="Unlocked gate"
                                            v-model="form.trampoline_unlocked"
                                            value="Unlocked gate">

                                </v-checkbox>
                            </v-col>

                            <v-col>

                                <v-checkbox label="Poor condition"
                                            v-model="form.trampoline_poor"
                                            value="Poor condition">

                                </v-checkbox>
                            </v-col>


                        </v-row>


                    </v-col>
                    <v-col cols="1" style="border:2px solid black">

                        <v-checkbox label=" "
                                    v-model="form.trampoline_other"
                                    value="Other">

                        </v-checkbox>

                    </v-col>


                </v-row>


                LIABILITY CONCERNS
                <v-row>
                    <v-col cols="2" style="border:2px solid black">
                        Item


                    </v-col>
                    <v-col cols="2">
                        <v-row>
                            <v-col cols="12" style="border:2px solid black">
                                Degree


                            </v-col>
                            <v-col cols="6" style="border:2px solid black">
                                Major


                            </v-col>
                            <v-col cols="6" style="border:2px solid black">
                                Minor
                            </v-col>

                        </v-row>
                    </v-col>
                    <v-col cols="7" style="border:2px solid black">
                        Specific problem


                    </v-col>
                    <v-col cols="1" style="border:2px solid black">
                        Other


                    </v-col>

                    <v-col cols="2" style="border:2px solid black">
                        Business

                    </v-col>

                    <v-col cols="2" style="border:2px solid black">
                        <v-radio-group
                            v-model="form.business_degree"
                            row

                        >
                            <v-radio
                                value="Major"
                            ></v-radio>
                            <v-radio
                                value="Minor"
                            ></v-radio>

                        </v-radio-group>


                    </v-col>
                    <v-col cols="7" style="border:2px solid black">
                        <v-row>

                            <v-col>

                                <v-checkbox label="In House"
                                            v-model="form.business_in_house"
                                            value="In House">

                                </v-checkbox>
                            </v-col>
                            <v-col>

                                <v-checkbox label="On property"
                                            v-model="form.business_on_property"
                                            value="On property">

                                </v-checkbox>
                            </v-col>
                            <v-col>

                                <v-checkbox label="On property"
                                            v-model="form.business_farm"
                                            value="On property">

                                </v-checkbox>
                            </v-col>
                            <v-col>

                                <v-checkbox label="Day care"
                                            v-model="form.business_daycare"
                                            value="Day care">

                                </v-checkbox>
                            </v-col>



                        </v-row>


                    </v-col>
                    <v-col cols="1" style="border:2px solid black">

                        <v-checkbox label=" "
                                    v-model="form.business_other"
                                    value="Other">

                        </v-checkbox>

                    </v-col>

                    <v-col cols="2" style="border:2px solid black">
                        Sidewalks/Driveway

                    </v-col>

                    <v-col cols="2" style="border:2px solid black">
                        <v-radio-group
                            v-model="form.sidewalks_degree"
                            row

                        >
                            <v-radio

                                value="Major"
                            ></v-radio>
                            <v-radio

                                value="Minor"
                            ></v-radio>

                        </v-radio-group>


                    </v-col>
                    <v-col cols="7" style="border:2px solid black">
                        <v-row>

                            <v-col>

                                <v-checkbox label="Cracking"
                                            v-model="form.sidewalks_cracking"
                                            value="Cracking">

                                </v-checkbox>
                            </v-col>
                            <v-col>

                                <v-checkbox label="Crumbling"
                                            v-model="form.sidewalks_crumbling"
                                            value="Crumbling">

                                </v-checkbox>
                            </v-col>
                            <v-col>

                                <v-checkbox label="Uneven"
                                            v-model="form.sidewalks_uneven"
                                            value="Uneven">

                                </v-checkbox>
                            </v-col>


                        </v-row>


                    </v-col>
                    <v-col cols="1" style="border:2px solid black">

                        <v-checkbox label=" "
                                    v-model="form.sidewalks_other"
                                    value="Other">

                        </v-checkbox>

                    </v-col>

                    <v-col cols="2" style="border:2px solid black">
                        Yard

                    </v-col>

                    <v-col cols="2" style="border:2px solid black">
                        <v-radio-group
                            v-model="form.yard_degree"
                            row

                        >
                            <v-radio

                                value="Major"
                            ></v-radio>
                            <v-radio

                                value="Minor"
                            ></v-radio>

                        </v-radio-group>


                    </v-col>
                    <v-col cols="7" style="border:2px solid black">
                        <v-row>

                            <v-col>

                                <v-checkbox label="Trash"
                                            v-model="form.yard_trash"
                                            value="Trash">

                                </v-checkbox>
                            </v-col>
                            <v-col>

                                <v-checkbox label="Automobiles"
                                            v-model="form.yard_auto"
                                            value="Automobiles">

                                </v-checkbox>
                            </v-col>
                            <v-col>

                                <v-checkbox label="Appliances"
                                            v-model="form.yard_applience"
                                            value="Appliances">

                                </v-checkbox>
                            </v-col>
                            <v-col>

                                <v-checkbox label="Debris"
                                            v-model="form.yard_debris"
                                            value="Debris">

                                </v-checkbox>
                            </v-col>
                            <v-col>

                                <v-checkbox label="Unkept"
                                            v-model="form.yard_unkept"
                                            value="Unkept">

                                </v-checkbox>
                            </v-col>


                        </v-row>


                    </v-col>
                    <v-col cols="1" style="border:2px solid black">

                        <v-checkbox label=" "
                                    v-model="form.yard_other"
                                    value="Other">

                        </v-checkbox>

                    </v-col>

                    <v-col cols="2" style="border:2px solid black">
                        Trees

                    </v-col>

                    <v-col cols="2" style="border:2px solid black">
                        <v-radio-group
                            v-model="form.trees_degree"
                            row

                        >
                            <v-radio

                                value="Major"
                            ></v-radio>
                            <v-radio

                                value="Minor"
                            ></v-radio>

                        </v-radio-group>


                    </v-col>
                    <v-col cols="7" style="border:2px solid black">
                        <v-row>

                            <v-col>

                                <v-checkbox label="Touching"
                                            v-model="form.trees_touching"
                                            value="Touching">

                                </v-checkbox>
                            </v-col>
                            <v-col>

                                <v-checkbox label="Overhanging"
                                            v-model="form.trees_over"
                                            value="Overhanging">

                                </v-checkbox>
                            </v-col>
                            <v-col>

                                <v-checkbox label="Rotting"
                                            v-model="form.trees_rotting"
                                            value="Rotting">

                                </v-checkbox>
                            </v-col>
                            <v-col>

                                <v-checkbox label="Falling"
                                            v-model="form.trees_falling"
                                            value="Falling">

                                </v-checkbox>
                            </v-col>
                            <v-col>

                                <v-checkbox label="Leaning"
                                            v-model="form.trees_leaning"
                                            value="Leaning">

                                </v-checkbox>
                            </v-col>


                        </v-row>


                    </v-col>
                    <v-col cols="1" style="border:2px solid black">

                        <v-checkbox label=" "
                                    v-model="form.trees_other"
                                    value="Other">

                        </v-checkbox>

                    </v-col>

                    <v-col cols="2" style="border:2px solid black">
                        Dog

                    </v-col>

                    <v-col cols="2" style="border:2px solid black">
                        <v-radio-group
                            v-model="form.dog_degree"
                            row

                        >
                            <v-radio

                                value="Major"
                            ></v-radio>
                            <v-radio

                                value="Minor"
                            ></v-radio>

                        </v-radio-group>


                    </v-col>
                    <v-col cols="7" style="border:2px solid black">
                        <v-row>

                            <v-col>

                                <v-checkbox label="Aggressive"
                                            v-model="form.dog_agressive"
                                            value="Aggressive">

                                </v-checkbox>
                            </v-col>
                            <v-col>

                                <v-checkbox label="Vicious Breed?"
                                            v-model="form.dog_vicious"
                                            value="Vicious Breed?">

                                </v-checkbox>
                            </v-col>


                        </v-row>


                    </v-col>
                    <v-col cols="1" style="border:2px solid black">

                        <v-checkbox label=" "
                                    v-model="form.dog_other"
                                    value="Other">

                        </v-checkbox>

                    </v-col>

                </v-row>


                Exterior Comments
                <v-row>

                    <v-col cols="12">

                        <v-textarea
                            v-model="form.exterior_comments">


                        </v-textarea>
                    </v-col>

                </v-row>

                SECTION II INTERIOR CONDITIONS

                HEATING:


                <v-row>
                    <v-radio-group
                        v-model="form.heating_type"
                        row
                        label="1. Type:"
                    >
                        <v-radio
                            label="Central"
                            value="Central"
                        ></v-radio>
                        <v-radio
                            label="Space Heaters"
                            value="Space Heaters"
                        ></v-radio>
                        <v-radio
                            label="Solid Fuel Stove"
                            value="Solid Fuel Stove"
                        ></v-radio>
                        <v-radio
                            label="Other (Describe)"
                            value="Other (Describe)"
                        ></v-radio>

                    </v-radio-group>

                    <v-radio-group
                        v-model="form.heating_fuel"
                        row
                        label="2. Fuel"
                    >
                        <v-radio
                            label="Electric"
                            value="Electric"
                        ></v-radio>
                        <v-radio
                            label="Gas"
                            value="Gas"
                        ></v-radio>
                        <v-radio
                            label="Oil"
                            value="Oil"
                        ></v-radio>
                        <v-radio
                            label="Kerosene"
                            value="Kerosene"
                        ></v-radio>
                        <v-radio
                            label="Solid Fuel"
                            value="Solid Fuel"
                        ></v-radio>
                        <v-radio
                            label="Other"
                            value="Other"
                        ></v-radio>
                    </v-radio-group>

                    <v-radio-group
                        v-model="form.heating_method"
                        row
                        label="3. Method"
                    >
                        <v-radio
                            label="Forced Air"
                            value="Forced Air"
                        ></v-radio>
                        <v-radio
                            label="Steam"
                            value="Steam"
                        ></v-radio>
                        <v-radio
                            label="Hot Water"
                            value="Hot Water"
                        ></v-radio>
                        <v-radio
                            label="Other (Describe)"
                            value="Other (Describe)"
                        ></v-radio>

                    </v-radio-group>

                    <v-radio-group
                        v-model="form.heating_sub_devices"
                        row
                        label="Sub-standard heating device(s): (not in good working order; unsafe location; No protective mats under space heaters, no cut -off switch, etc.)"
                    >
                        <v-radio
                            label="Yes"
                            value="Yes"
                        ></v-radio>
                        <v-radio
                            label="No"
                            value="No"
                        ></v-radio>
                    </v-radio-group>

                    <v-radio-group
                        v-model="form.heating_sub_fuel"
                        row
                        label="
                        Sub-standard fuel supply: (Unsafe piping, subject to damage, leak in supply, etc.)"
                    >
                        <v-radio
                            label="Yes"
                            value="Yes"
                        ></v-radio>
                        <v-radio
                            label="No"
                            value="No"
                        ></v-radio>
                    </v-radio-group>

                    <v-radio-group
                        v-model="form.heating_sub_vent"
                        row
                        label="Sub-standard venting: (vent in contact with combustible partition, ceiling, wall, roof etc.)"
                    >
                        <v-radio
                            label="Yes"
                            value="Yes"
                        ></v-radio>
                        <v-radio
                            label="No"
                            value="No"
                        ></v-radio>
                    </v-radio-group>


                </v-row>


                ELECTRICAL:

                <v-row>

                    <v-radio-group
                        v-model="form.electrical_wire"
                        row
                        label="1. Wire type:"
                    >
                        <v-radio
                            label="Romex"
                            value="Romex"
                        ></v-radio>
                        <v-radio
                            label="Conduit"
                            value="Conduit"
                        ></v-radio>
                        <v-radio
                            label="BX"
                            value="BX"
                        ></v-radio>
                        <v-radio
                            label="K&T"
                            value="K&T"
                        ></v-radio>
                        <v-radio
                            label="Unknown"
                            value="Unknown"
                        ></v-radio>
                    </v-radio-group>
                    <v-radio-group
                        v-model="form.electrical_wire_gauge"
                        row
                        label="2. If identifiable, wire gauge:"
                    >
                        <v-radio
                            label="14"
                            value="14"
                        ></v-radio>
                        <v-radio
                            label="12"
                            value="12"
                        ></v-radio>
                        <v-radio
                            label="10"
                            value="10"
                        ></v-radio>

                    </v-radio-group>
                    <v-radio-group
                        v-model="form.electrical_wire_update"
                        row
                        label="3. If identifiable, wiring updated?"
                    >
                        <v-radio
                            label="Yes"
                            value="Yes"
                        ></v-radio>
                        <v-radio
                            label="No"
                            value="No"
                        ></v-radio>

                    </v-radio-group>
                    <v-radio-group
                        v-model="form.fuses"
                        row
                        label="4."
                    >
                        <v-radio
                            label="CB"
                            value="CB"
                        ></v-radio>
                        <v-radio
                            label="Fuses"
                            value="Fuses"
                        ></v-radio>


                    </v-radio-group>
                    <v-radio-group
                        v-model="form.if_edison"
                        row
                        label="a. If Edison"
                    >
                        <v-radio
                            label="15A"
                            value="15A"
                        ></v-radio>
                        <v-radio
                            label="20A"
                            value="20A"
                        ></v-radio>
                        <v-radio
                            label="30A"
                            value="30A"
                        ></v-radio>


                    </v-radio-group>
                    <v-radio-group
                        v-model="form.type_s"
                        row
                        label="b. If Type S"
                    >
                        <v-radio
                            label="15A"
                            value="15A"
                        ></v-radio>
                        <v-radio
                            label="20A"
                            value="20A"
                        ></v-radio>
                        <v-radio
                            label="30A"
                            value="30A"
                        ></v-radio>


                    </v-radio-group>

                    <v-radio-group
                        v-model="form.electrical_unsafe"
                        row
                        label="5.Unsafe or sub-standard conditions?(exposed wiring, unsafe extension cords, overloading or overfusing, etc)"
                    >
                        <v-radio
                            label="Yes"
                            value="Yes"
                        ></v-radio>
                        <v-radio
                            label="No"
                            value="No"
                        ></v-radio>

                    </v-radio-group>


                </v-row>

                PHYSICAL CONDITIONS:

                <v-row>

                    <v-radio-group
                        v-model="form.physical_evidence"
                        row
                        label="Evidence of water damage or mold damage."
                    >
                        <v-radio
                            label="Yes"
                            value="Yes"
                        ></v-radio>
                        <v-radio
                            label="No"
                            value="No"
                        ></v-radio>

                    </v-radio-group>
                    <v-radio-group
                        v-model="form.physical_deteriorating"
                        row
                        label="Deteriorating wall or plaster, loose wall paper, paint peeling or sagging floors."
                    >
                        <v-radio
                            label="Yes"
                            value="Yes"
                        ></v-radio>
                        <v-radio
                            label="No"
                            value="No"
                        ></v-radio>

                    </v-radio-group>
                    <v-radio-group
                        v-model="form.physical_damage"
                        row
                        label="Un-repaired damage."
                    >
                        <v-radio
                            label="Yes"
                            value="Yes"
                        ></v-radio>
                        <v-radio
                            label="No"
                            value="No"
                        ></v-radio>

                    </v-radio-group>
                </v-row>


                GENERAL HOUSEKEEPING:

                <v-row>
                    <v-radio-group
                        v-model="form.general_none"
                        row
                        label="None"
                    >
                        <v-radio
                            label="Light"
                            value="Light"
                        ></v-radio>
                        <v-radio
                            label="Moderate"
                            value="Moderate"
                        ></v-radio>
                        <v-radio
                            label="Severe"
                            value="Severe"
                        ></v-radio>

                    </v-radio-group>

                    <v-radio-group
                        v-model="form.general_rubbish"
                        row
                        label="Rubbish, litter or debris?"
                    >
                        <v-radio
                            label="Yes"
                            value="Yes"
                        ></v-radio>
                        <v-radio
                            label="No"
                            value="No"
                        ></v-radio>

                    </v-radio-group>

                    <v-radio-group
                        v-model="form.general_other"
                        row
                        label="Other substandard housekeeping conditions? (combustible stored near heating, improper storage of flammable materials, etc.)"
                    >
                        <v-radio
                            label="Yes"
                            value="Yes"
                        ></v-radio>
                        <v-radio
                            label="No"
                            value="No"
                        ></v-radio>

                    </v-radio-group>


                </v-row>

                SPECIAL HAZARDS:
                <v-row>
                    <v-radio-group
                        v-model="form.hazard_"
                        row

                    >
                        <v-radio
                            label="Yes"
                            value="Yes"
                        ></v-radio>
                        <v-radio
                            label="No"
                            value="No"
                        ></v-radio>

                    </v-radio-group>
   <v-radio-group
                        v-model="form.hazard_smoke_inadequate"
                    label="a. Inadequate SMOKE DECTECTORS: "

                    >
                        <v-radio
                            label="insufficient number"
                            value="insufficient number"
                        ></v-radio>
                        <v-radio
                            label="not working"
                            value="not working"
                        ></v-radio>
                        <v-radio
                            label="not properly located, etc."
                            value="not properly located, etc."
                        ></v-radio>

                    </v-radio-group>

                </v-row>
                <v-col>
                    <v-text-field label="b. Number of working smoke detectors: #"
                                  v-model="form.hazard_smoke_number">


                    </v-text-field>
                </v-col>

                <v-row>


                    <v-radio-group
                        v-model="form.hazard_vaccant"

                        label="Vacant or Unoccupied?"
                    >
                        <v-radio
                            label="Yes"
                            value="Yes"
                        ></v-radio>
                        <v-radio
                            label="No"
                            value="No"
                        ></v-radio>

                    </v-radio-group>


                </v-row>

                CONVERSION:
                <v-row>


                    <v-radio-group
                        v-model="form.conversion_from"
                        row
                        label="Converted from to"
                    >
                        <v-radio
                            label="Yes"
                            value="Yes"
                        ></v-radio>
                        <v-radio
                            label="No"
                            value="No"
                        ></v-radio>

                    </v-radio-group>

                    <v-radio-group
                        v-model="form.conversion_beyond"
                        row
                        label="Use of premises beyond design occupancy limits?"
                    >
                        <v-radio
                            label="Yes"
                            value="Yes"
                        ></v-radio>
                        <v-radio
                            label="No"
                            value="No"
                        ></v-radio>
                    </v-radio-group>
                        <v-radio-group
                            v-model="form.conversion_other"
                            row
                            label="Other substandard conversion features?"
                        >
                            <v-radio
                                label="Yes"
                                value="Yes"
                            ></v-radio>
                            <v-radio
                                label="No"
                                value="No"
                            ></v-radio>

                        </v-radio-group>


                </v-row>

                REHAB:
                <v-row>


                    <v-radio-group
                        v-model="form.rehab"
                        row
                        label="       Rehab work in progress."
                    >
                        <v-radio
                            label="Yes"
                            value="Yes"
                        ></v-radio>
                        <v-radio
                            label="No"
                            value="No"
                        ></v-radio>

                    </v-radio-group>

                    <v-textarea v-model="form.rehab_text"
                        label="Please describe fully any recent or ongoing rehab work to the property(take photos of all work/rehab area).">

                    </v-textarea>
                </v-row>
                Interior Comments

                <v-row>

                    <v-textarea v-model="form.interior_comments">

                    </v-textarea>
                </v-row>

                <v-col>

                    <v-row>
                        <v-col>
                            Contact # 1: Date Call to :
                        </v-col>
                        <v-col>
                            <v-text-field
                            v-model="form.contact1_call">

                            </v-text-field>
                        </v-col>

                        <v-radio-group
                            v-model="form.contact1"
                            row

                        >
                            <v-radio

                                label="Insured"
                                value="Insured"
                            ></v-radio>
                            <v-radio

                                label="Tenant"
                                value="Tenant"
                            ></v-radio>
                            <v-radio

                                label="Agent"
                                value="Agent"
                            ></v-radio>

                        </v-radio-group>
                        <v-col>
                            <v-text-field
                            label="Result of call"
                            v-model="form.contact1_result">

                            </v-text-field>
                        </v-col>
                    </v-row>



                </v-col>

                <v-col>

                    <v-row>
                        <v-col>
                            Contact # 2: Date Call to :
                        </v-col>
                        <v-col>
                            <v-text-field
                            v-model="form.contact2_call">

                            </v-text-field>
                        </v-col>

                        <v-radio-group
                            v-model="form.contact2"
                            row

                        >
                            <v-radio

                                label="Insured"
                                value="Insured"
                            ></v-radio>
                            <v-radio

                                label="Tenant"
                                value="Tenant"
                            ></v-radio>
                            <v-radio

                                label="Agent"
                                value="Agent"
                            ></v-radio>

                        </v-radio-group>
                        <v-col>
                            <v-text-field
                            label="Result of call"
                            v-model="form.contact2_result">

                            </v-text-field>
                        </v-col>
                    </v-row>



                </v-col>

                <v-col>

                    <v-row>
                        <v-col>
                            Contact # 3: Date Call to :
                        </v-col>
                        <v-col>
                            <v-text-field
                            v-model="form.contact3_call">

                            </v-text-field>
                        </v-col>

                        <v-radio-group
                            v-model="form.contact3"
                            row

                        >
                            <v-radio

                                label="Insured"
                                value="Insured"
                            ></v-radio>
                            <v-radio

                                label="Tenant"
                                value="Tenant"
                            ></v-radio>
                            <v-radio

                                label="Agent"
                                value="Agent"
                            ></v-radio>

                        </v-radio-group>
                        <v-col>
                            <v-text-field
                            label="Result of call"
                            v-model="form.contact3_result">

                            </v-text-field>
                        </v-col>
                    </v-row>



                </v-col>


                OVERVIEW EVALUATION


                    <v-row>
                        <v-col>
                            <v-text-field label=" Estimated market value : $"
                                            v-model="form.estimated_value">

                            </v-text-field>

                        </v-col>
                        <v-col>
                            <v-text-field label="Estimated Year Built / Age:"
                                          v-model="form.estimated_year">

                            </v-text-field>

                        </v-col>

                    </v-row>
                    <v-row>


                        <v-radio-group
                            v-model="form.average"
                            row
                            label="Insurability:"
                        >
                            <v-radio

                                label="ABOVE AVERAGE"
                                value="ABOVE AVERAGE"
                            ></v-radio>
                            <v-radio

                                label="AVERAGE"
                                value="AVERAGE"
                            ></v-radio>
                            <v-radio

                                label="BELOW AVERAGE"
                                value="BELOW AVERAGE"
                            ></v-radio>

                        </v-radio-group>

                    </v-row>

                <v-row>

                    <v-textarea
                        label="Comment on all MAJOR concerns"
                        v-model="form.major_comments">

                    </v-textarea>
                </v-row>
     <v-row>

                    <v-textarea
                        label="Special Comments"
                        v-model="form.special_comments">

                    </v-textarea>
                </v-row>

                <v-btn @click="submit">
                    Add
                </v-btn>


            </v-form>
        </v-container>
    </user-dashboard-layout>
</template>

<script>
import UserDashboardLayout from "../../../layouts/UserDashboardLayout";
import axios from 'axios';

export default {
    name: "Inspection",
    components: {UserDashboardLayout},
    data: function () {
        return {
            menu:false,
            menu2:false,
            menu3:false,
      //      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            form: {
                contact2_call:'',
                contact2_result:'',
                contact2:'',
                contact1_call:'',
                contact1_result:'',
                contact1:'',
                contact3_call:'',
                contact3_result:'',
                contact3:'',
                type_s:'',
                fuses:'',
                inspector: '',
                fire_dept: '',
                fire_evidence: '',
                construction: '',
                company: '',
                inspection_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                underwriter: '',
                policy_type: '',
                policy_number: '',
                insurance_amount: '',
                date_ordered:  (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

                date_returned:  (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

                underwriter_review: '',
                applicant_name: '',
                property_location: '',
                person_to_contact: '',
                agent_name: '',
                phone: '',
                phone2: '',
                interview: '',
                families_apartments: '',
                occupancy: '',
                vacancy_determined: '',
                if_vacant: '',
                damage_evidence: '',
                firewalls_between: '',
                within_firewall: '',
                foundation_degree: '',
                foundation_cracking: '',
                foundation_crumbling: '',
                foundation_uneven: '',
                foundation_open: '',
                foundation_non_continuous: '',
                foundation_other: '',
                steps_degree: '',
                steps_no_railing: '',
                steps_cracking: '',
                steps_crumbling: '',
                steps_rotting: '',
                steps_leaning: '',
                steps_other: '',
                porch_no_railing: '',
                porch_degree: '',
                porch_cracking: '',
                porch_crumbling: '',
                porch_rotting: '',
                porch_other: '',
                roof_degree: '',
                roof_curled: '',
                roof_crumbling: '',
                roof_rotting: '',
                roof_uneven: '',
                roof_patched: '',
                roof_moss: '',
                roof_other: '',
                siding_degree: '',
                siding_crumbling: '',
                siding_rotting: '',
                siding_peeling: '',
                siding_damaged: '',
                siding_missing: '',
                siding_other: '',
                brick_degree: '',
                brick_cracking: '',
                brick_crumbling: '',
                brick_tuck: '',
                brick_other: '',
                soffits_degree: '',
                soffits_rotting: '',
                soffits_missing: '',
                soffits_damaged: '',
                soffits_other: '',
                gutters_degree:'',
                gutters_rotting:'',
                gutters_missing:'',
                gutters_damaged:'',
                gutters_other:'',
                chimney_degree: '',
                chimney_cracking: '',
                chimney_crumbling: '',
                chimney_tuck: '',
                chimney_leaninng: '',
                chimney_other: '',
                window_degree: '',
                window_rotting: '',
                window_peeling: '',
                window_damaged: '',
                window_broken: '',
                window_other: '',
                upper_door:'',
                upper_door_deck:'',
                upper_door_steps:'',
                upper_door_railing:'',
                garage_degree:'',
                garage_siding:'',
                garage_foundation:'',
                garage_roof:'',
                garage_paint:'',
                garage_windows:'',
                garage_doors:'',
                garage_other:'',
                shed_degree:'',
                shed_siding:'',
                shed_foundation:'',
                shed_roof:'',
                shed_paint:'',
                shed_windows:'',
                shed_doors:'',
                shed_other:'',
                out_building_degree:'',
                out_building_siding:'',
                out_building_foundation:'',
                out_building_roof:'',
                out_building_paint:'',
                out_building_windows:'',
                out_building_doors:'',
                out_building_other:'',
                pool_degree:'',
                pool_inground:'',
                pool_above:'',
                pool_unfenced:'',
                pool_unlocked:'',
                pool_windows:'',
                pool_poor:'',
                pool_other:'',
                trampoline_degree:'',
                trampoline_unfenced:'',
                trampoline_unlocked:'',
                trampoline_poor:'',
                trampoline_other:'',
                business_degree:'',
                business_in_house:'',
                business_on_property:'',
                business_farm:'',
                business_daycare:'',
                business_other:'',
                sidewalks_degree:'',
                sidewalks_cracking:'',
                sidewalks_crumbling:'',
                sidewalks_uneven:'',
                sidewalks_other:'',
                yard_degree:'',
                yard_trash:'',
                yard_auto:'',
                yard_applience:'',
                yard_debris:'',
                yard_unkept:'',
                yard_other:'',
                trees_degree:'',
                trees_touching:'',
                trees_over:'',
                trees_rotting:'',
                trees_falling:'',
                trees_leaning:'',
                trees_other:'',
                dog_degree:'',
                dog_agressive:'',
                dog_vicious:'',
                dog_breed:'',
                dog_other:'',
                exterior_comments:'',
                heating_type:'',
                heating_fuel:'',
                heating_method:'',
                heating_sub_devices:'',
                heating_sub_fuel:'',
                heating_sub_vent:'',
                electrical_wire:'',
                electrical_wire_gauge:'',
                electrical_wire_update:'',
                electrical_unsafe:'',
                physical_evidence:'',
                physical_deteriorating:'',
                physical_damage:'',
                general_none:'',
                general_rubbish:'',
                general_other:'',
                hazard_smoke:'',
                hazard_smoke_inadequate:'',
                hazard_smoke_number:'',
                hazard_commercial:'',
                hazard_vaccant:'',
                hazard_:'',
                conversion_from:'',
                conversion_beyond:'',
                conversion_other:'',
                rehab:'',
                rehab_text:'',
                interior_comments:'',
                major_comments:'',
                special_comments:'',
                estimated_value:'',
                estimated_year:'',
                insurability:'',
                if_edison:'',
                if_stype:'',
                below_average:'',
                above_average:'',
                average:'',






            }


        }
    },

    methods: {
        submit() {
            axios({
              url:  '/api/inspection',
                method: 'POST',
                data:this.form,
                responseType: 'blob',

            })
                .then((response) => {
                    console.log(this.form.inspector)
                    if(response.status == 200){
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'test.pdf');
                        document.body.appendChild(link);
                        link.click();
                    }
                })
                .catch((error) => {
                    console.log(error)
                }).finally(() => {
                //Perform action in always
            });

        }
    }

}
</script>

<style scoped>

</style>
